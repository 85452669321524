<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">PURCHASE ORDER SUMMARY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
          <span class="headline font-weight-light"
                v-if="collectors_data.length>0">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="collectors_data"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="activerow(item)"
            :class="{'info black--text': item.id===selectedDepositId}"
          >
            <td>{{ item.id }}</td>
            <td>
              {{ item.date}}
            </td>
            <td>
              {{ item.type_of_order }}
            </td>
            <td>
              {{ item.type_of_payment}}
            </td>
            <td>
              {{ item.reference_no}}
            </td>
            <td>
              {{ item.code}}
            </td>
            <td>
              {{ formatPrice(item.grand_total) }}
            </td>
            <td>
              <div v-if="item.grand_total<=0">
                <v-chip small color="warning" dark>PENDING IN CASHIER</v-chip>
              </div>
              <div v-else-if="item.deposit_slip_id===null">
                <v-chip small color="error" dark>NOT YET DEPOSITED</v-chip>
              </div>
              <div v-else>
                <v-chip small color="success" dark>DEPOSITED</v-chip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiEyeOutline, mdiToggleSwitch} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.get_transaction_month_history(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
          mdiToggleSwitch,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'ID', value: 'id', sortable: false},
          {text: 'DATE & TTME', value: 'date_of_deposit', sortable: false},
          {text: 'TYPE OF ORDER', value: 'type_of_payment', sortable: false},
          {text: 'MODE OF PAYMENT', value: 'type_of_payment', sortable: false},
          {text: 'REF. #', value: 'date_of_deposit', sortable: false},
          {text: 'CODE', value: 'time_of_deposit', sortable: false},
          {text: 'AMOUNT', value: 'time_of_deposit', sortable: false},
          {text: 'STATUS', value: 'amount', sortable: false},],

        data: [],
        bank_depositories: [],
        collectors_data: [],
        collectors_remittances_data: [],

        month_of_items: [],
        month_of: '',
        selectedDepositId: -1,
      }
    },
    computed: {
      ...mapGetters('authentication', ['position','company_name', 'branch_id', 'branch_contact_no', 'branch_address', 'branch', 'branch_id_selected']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('purchase_order_slip', ['get_purchase_report']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      monthly_remittances() {
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of)
        if (index != -1) {
          const data = new FormData()
          data.append('branch_id', this.branch_id === 1 ? this.branch_id_selected : this.branch_id);
          data.append('month_of', this.month_of_items[index].month_of);
          data.append('type_of_payment', this.type_of_payment);
          data.append('type_of_order', this.type_of_order);
          this.get_purchase_report(data)
            .then(response => {
              this.selectedDepositId = -1
              this.collectors_data = response.data
              this.collectors_remittances_data = []
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      activerow(value) {
        this.data = value
        this.bank_depositories = value.bank_depositories
        this.selectedDepositId = value.id;
        this.collectors_remittances_data = value.liquidations
      },
      get_amount_of_purchase_order(value) {
        var amm = 0
        value.forEach(function (item) {
          amm += parseFloat(item.total)
        })
        return amm;
      },
      print_data() {
        var imgData = this.company_logo
        var payments_array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)
        var month = this.month_of_items[indexMonth].month_of

        var total_amount = 0
        payments_array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'DATE & TIME', alignment: 'center', style: 'label'},
            {text: 'TYPE OF ORDER', alignment: 'center', style: 'label'},
            {text: 'MODE OF PAYMENT', alignment: 'center', style: 'label'},
            {text: 'REF#', alignment: 'center', style: 'label'},
            {text: 'CODE', alignment: 'center', style: 'label'},
            {text: 'AMOUNT', alignment: 'center', style: 'label'},
            {text: 'STATUS', alignment: 'center', style: 'label'},
          ]
        )
        if (this.collectors_data.length > 0) {
          var total_amount = 0
          this.collectors_data.forEach(function (item, index, payment) {
            total_amount += parseFloat(item.grand_total)
            payments_array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.date, alignment: 'center'},
                {text: item.type_of_order, alignment: 'center'},
                {text: item.type_of_payment, alignment: 'center'},
                {text: item.reference_no, alignment: 'center'},
                {text: item.code, alignment: 'center'},
                {
                  text: (item.grand_total / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'center'
                },
                {text: item.grand_total>0?(item.deposit_slip_id===null?'NOT YET DEPOSITED':'DEPOSITED'):'PENDING CASHIER', alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: (total_amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'center',
                    color: 'red',
                    border: [false, false, false, false],
                  },
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'PORTRAIT',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    this.company_name,
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: '* CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'PURCHASE ORDER SUMMARY', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch_selected, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [30, 90, 50, 50, 50, 50, 50, 80,],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Teller', style: 'aa_placeholder'},
                    {text: 'Head Office', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with GL-Express System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
